// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormatKey } from 'src/app/core/utils/global-functions';

@Component({
  selector: 'app-mismatch-chart',
  templateUrl: './mismatch-chart.component.html',
  styleUrls: ['./mismatch-chart.component.scss']
})
export class MismatchChartComponent implements OnInit {

  modalTitle: string;
  subTitle: string;

  data: any;
  options: any;
  level: number = 0;
  sizes: any[] = [];
  // sortedSizes = ['04.0 M','05.0 M','06.0 M','07.0 M','08.0 M','09.0 M','10.0 M'];
  // sortedSizes = ['6.0 M','6.5 M','7.0 M','7.5 M','8.0 M','8.5 M','9.0 M','9.5 M','10.0 M','11.0 M'];
  sizeDataPoints: any[] = [];

  metric: string;
  isBuy: boolean;

  threshold = 0.5;
  isMismatch: boolean;

  colors = {
    over: 'rgb(0,0,255)',
    under: 'rgb(255,0,0)',
    over2: 'rgba(0,0,255,0.25)',
    under2: 'rgba(255,0,0,0.25)',
    buy: 'black',
    buy2: 'grey'
  }

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    console.log("mismatch: ", this.config.data);
    this.sizes = this.config.data.sizes;
    this.level = this.config.data.level;
    this.metric = this.config.data.metric;
    this.isBuy = this.metric === 'Buy'

    if (this.isBuy) {
      this.modalTitle = "Buy Percent"
    } else {
      this.modalTitle = "Mismatch Error"
    }
    // const mapData = () => {
    //   let obj = {}
    //   Object.keys(this.config.data.data).map(key => {
    //     const updatedKey = key.includes('|') ? key.split('|')[1] : key
    //     Object.assign(obj, {[FormatKey(updatedKey)]: this.config.data.data[key]})

    //   })
    //   if (obj["store"] === "All") {
    //     obj['threshold'] = obj['numStores'] * 0.5;
    //   } else {
    //     obj['threshold'] = 0.5;
    //   }
    //   return obj
    // }
    // let data = mapData()

    // console.log("data: ", data)
    // this.subTitle = `Store: ${data['store'] || "Total"}`;
    // this.threshold = data['threshold'];
    // console.log("subtitle: ", this.subTitle);

    // this.isMismatch = data['metric'] === 'Error';

    // if (this.isMismatch) {
    //   this.modalTitle = 'Total Error';
    // } else {
    //   this.modalTitle = 'Total Buy'
    // }

    this.calcChartData(this.config.data.selectedRows);
  }
  // Function to calculate the bar chart data points
  calcChartData(data) {
    const chartData = [];
    console.log('metric', this.metric)
    console.log('level', this.level)
    console.log('data', data)
    if (this.isBuy) {

      switch (this.level) {
        case 0:
          var dataTotal = []
          var buy_total = 0
          this.sizes.forEach(size => {
            buy_total += data[0][size.toLowerCase()].qty_bought;
          });
          this.sizes.forEach(size => {
            dataTotal.push({
              x: size,
              y: data[0][size.toLowerCase()].qty_bought / buy_total
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.buy,
              data: dataTotal,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel}`
            })
          });
          break;
        case 1:
          var dataChannel = []
          var buy_total = 0
          this.sizes.forEach(size => {
            buy_total += data[0][size.toLowerCase()].qty_bought;
          });
          this.sizes.forEach(size => {
            dataChannel.push({
              x: size,
              y: data[0][size.toLowerCase()].qty_bought / buy_total
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.buy,
              data: dataChannel,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel}`
            })
          });
          break;
        case 2:
          var dataStore = []
          var store_buy_total = 0
          this.sizes.forEach(size => {
            const size_vals =  data[0][size.toLowerCase()]
            if (size_vals) {
              store_buy_total += size_vals.qty_bought;
            }
          });
          this.sizes.forEach(size => {
            const size_vals =  data[0][size.toLowerCase()]
            dataStore.push({
              x: size,
              y: size_vals ? (size_vals.qty_bought / store_buy_total) : 0
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.buy,
              data: dataStore,
              i: 0,
              metric: 'store',
              label: `${data[0].location_id}`
            })
          });
          var dataChannel = []
          var channel_buy_total = 0
          this.sizes.forEach(size => {
            channel_buy_total += data[1][size.toLowerCase()].qty_bought;
          });
          this.sizes.forEach(size => {
            dataChannel.push({
              x: size,
              y: data[1][size.toLowerCase()].qty_bought / channel_buy_total
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.buy2,
              data: dataChannel,
              i: 1,
              metric: 'channel',
              label: `${data[0].channel}`
            })
          });
          break;
        default:
          console.error('invalid level', this.level)
          break;
      }
    } else {
      switch (this.level) {
        case 0:
          var dataTotalOver = []
          var dataTotalUnder = []
          this.sizes.forEach(size => {
            const obj = data[0][size.toLowerCase()]
            dataTotalOver.push({
              y: size,
              x: obj.qty_over
            });
            dataTotalUnder.push({
              y: size,
              x: -obj.qty_under
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.over,
              data: dataTotalOver,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel} - Over`
            })
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.under,
              data: dataTotalUnder,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel} - Under`
            })
          });
          break;
        case 1:
          var dataChannelOver = []
          var dataChannelUnder = []
          this.sizes.forEach(size => {
            const obj = data[0][size.toLowerCase()]
            dataChannelOver.push({
              y: size,
              x: obj ? obj.qty_over : 0
            });
            dataChannelUnder.push({
              y: size,
              x: -(obj ? obj.qty_under : 0)
            });
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.over,
              data: dataChannelOver,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel} - Over`
            })
          });
          chartData.push({
            ...this.getChartItem({
              backgroundColor: this.colors.under,
              data: dataChannelUnder,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel} - Under`
            })
          });
          break;
        case 2:
          var dataStoreOver = []
          var dataStoreUnder = []
          this.sizes.forEach(size => {
            const obj = data[0][size.toLowerCase()]
            dataStoreOver.push({
              y: size,
              x: obj ? obj.qty_over : 0
            });
            dataStoreUnder.push({
              y: size,
              x: -(obj ? obj.qty_under : 0)
            });
          });
          chartData.push({
            ...this.getChartItem({
              stack: 'store',
              backgroundColor: this.colors.over,
              data: dataStoreOver,
              i: 0,
              metric: 'store',
              label: `${data[0].location_id} - Over`
            })
          });
          chartData.push({
            ...this.getChartItem({
              stack: 'store',
              backgroundColor: this.colors.under,
              data: dataStoreUnder,
              i: 0,
              metric: 'store',
              label: `${data[0].location_id} - Under`
            })
          });
          var dataChannelOver = []
          var dataChannelUnder = []
          this.sizes.forEach(size => {
            const obj = data[1][size.toLowerCase()]
            dataChannelOver.push({
              y: size,
              x: obj ? obj.qty_over : 0
            });
            dataChannelUnder.push({
              y: size,
              x: -(obj ? obj.qty_under : 0)
            });
          });
          chartData.push({
            ...this.getChartItem({
              stack: 'channel',
              backgroundColor: this.colors.over2,
              data: dataChannelOver,
              i: 1,
              metric: 'channel',
              label: `${data[0].channel} - Over`
            })
          });
          chartData.push({
            ...this.getChartItem({
              stack: 'channel',
              backgroundColor: this.colors.under2,
              data: dataChannelUnder,
              i: 1,
              metric: 'channel',
              label: `${data[0].channel} - Under`
            })
          });
          break;
        default:
          console.error('invalid level', this.level)
          break;
      }
    }
    console.log("chartData: ", chartData)
    this.updateChart(chartData)
  }

  getChartItem(values) {
    const {data, i, label, backgroundColor, stack} = values
  //   {
  //     label: this.isMismatch ? `Error <= ${threshold} or >= -${threshold}` : 'Buy',
  //     backgroundColor: '#026873',
  //     order: 1,
  //     hoverBackgroundColor: '#011C26',
  //     maxBarThickness: 20,
  //     borderColor: '#026873',
  //     padding: '20px',
  //     minBarLength: 4,
  //     data: this.isMismatch ? this.sizeDataPoints.map(function(data) {
  //       const value = Number(data.x);
  //       console.log("value: ", value >= .5 );
  //       return value >= -threshold && value <= threshold ? data : null;
  //     }) : this.sizeDataPoints,
  //     fill: false,
  // },
    return {
      label: label,
      borderColor: backgroundColor,
      backgroundColor: backgroundColor,
      stack: stack,
      order: i,
      padding: '20px',
      data: data,
      borderWidth: 1,
      fill: false,
      tension: 0.1
    }
  }

  updateChart(chartData) {
    console.log('updating with chartdat',chartData)
    this.data = {
      // yLabels: this.sizes,
      datasets: chartData.filter(e => e),
    }
    this.options = {
      indexAxis: 'y',
      hover: {
        // Overrides the global setting
        mode: 'point',
        intersect: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
          display: false
      },
      legend: {
        position: 'top',
        align: 'center', // start or end
      },
      scales: this.isBuy ? {
        x: {
          type: 'category',
          labels: this.sizes,
        },
        y: {
          ticks: {
            padding: 10,
            callback: function(value, index, ticks) {
              return Math.round(value * 100) + '%'
            }
          },
          type: 'linear',
          position: 'left',
          min: 0
        }
      } : {
        x: {
          ticks: { padding: 10 },
          type: 'linear',
        },
        y: {
          stacked: true,
          type: 'category',
          labels: this.sizes,
          position: 'left'
        }
      },
    //   scales: {
    //     xAxes: {
    //         ticks: { padding: 10, stepSize: this.isMismatch ? undefined : 1 },
    //         stacked: true
    //     },
    //     yAxes: {
    //         ticks: { },
    //         stacked: true
    //     }
    // },
      plugins: {
        tooltip: {
          mode: 'point',
          intersect: false,
          axis: 'y',
          position: 'nearest',
          backgroundColor: "#111111",
          borderWidth: 1,
          borderColor: "#111111",
          displayColors: true,
          titleColor: "#eeeeee",
          callbacks: {
            title: (context) => {
              console.log("TITLE context", context)
              // let title =  data?.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].y
              // context.yLabel = title
              // context.label = title
              if (this.isBuy) {
                return `Size: ${context[0].formattedValue}`
              }
              else {
                return `Size: ${context[0].label}`
              }
            },
            label: (context) => {
              console.log("LABEL context", context)
              if (this.isBuy) {
                return `${context.dataset.label}: ${(parseFloat(context.label) * 100).toFixed(2)}%`
              }
              else {
                // remove negative sign
                var value = context.formattedValue
                value = value.startsWith('-') ? value.substring(1) : value
                return `${context.dataset.label}: ${value}`
              }
            },
            labelTextColor: (tooltipItem, chart) => {
              if (!this.isBuy
                  && (tooltipItem.value < -.5 || tooltipItem.value > .5)) {
                return '#E61700';
              } else {
                return '#eeeeee';
              }
            },
            // afterBody: function(t, d) {
            //   return 'loss 15%';  // return a string that you wish to append
            // }
          }
        }
      }
    }
  }

  close() {
    this.ref.close();
  }

}
