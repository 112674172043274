<!-- Header -->
<app-nav-bar pageTitle="Set Carton & Buy Parameters"></app-nav-bar>
<!-- Main -->
<main>
  <div class="g-main-body-container">
    <!-- Filter Panel -->
    <app-filter-sidenav>
      <app-filters-panel
        [filtersToDisplay]="externalFiltersToDisplay"
      ></app-filters-panel>
    </app-filter-sidenav>
    <!-- Center Container -->
    <div class="g-main-container-center">
      <!-- Action Buttons Container -->
      <div class="g-grid-actions-container"></div>
      <!-- AG Grid -->
      <mat-card class="g-mat-card-container ag-grid-wrapper">
        <ag-grid-angular
          #mainGrid
          id="set-carton-and-buy-params-grid"
          class="ag-theme-alpine ag-grid-container"
          [gridOptions]="gridOptions"
          [columnDefs]="columnDefs"
          (gridReady)="onGridReady($event)"
          [masterDetail]="true"
          [detailCellRendererParams]="detailCellRendererParams"
          [groupRemoveLowestSingleChildren]="true"
        ></ag-grid-angular>
      </mat-card>
    </div>
  </div>
</main>
