import { Component, OnInit } from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})

export class DashboardPageComponent implements OnInit {

  environment = environment;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    // console.log("route: ", this.router.url)
    // if (this.router.url === '/') this.onLogout();
  }

  onClickNav(path: string) {
    if (path != '/review-orders' || environment.btl) {
      this.router.navigateByUrl(path);
    }
  }

  // async onLogout() {
	// 	console.log("logout: ", Auth.currentAuthenticatedUser() )

	// 	try {
	// 		// this.router.navigateByUrl('/');
	// 		await Auth.signOut();
	// 		//  location.reload();
	// 	} catch (error) {
	// 		console.log('error signing out: ', error);
	// 	}
	// }

}
