import { CustomGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
import { v4 as uuidv4 } from 'uuid';
import { IsKeyInObj } from 'src/app/core/utils/global-functions';

export const RO_SharedGridOptions = (tabKey) => {
	return {
		columnTypes: {
			...CustomGridOptions.columnTypes,
			sourceDestCartonTooltip: {
				// valueFormatter: (params) => {
				//   if (params.node.level !== 2) {
				//     return '--';
				//   } else return params.value;
				// },
				tooltipComponent: 'sourceDestCartonTooltip',
			},
		},
		aggFuncs: {
			...CustomGridOptions.aggFuncs,
			buySummChannel: (params) => {
				if (params.rowNode.level === 0) {
					return 'Total';
				} else return params.values[0];
			},
			inputLinesStatus: (params) => {
				// console.log("inputlinesstatus params", params)
				if (params.rowNode.level == 1) {
					// console.log('row is one so returning', params.rowNode.allLeafChildren[0].data.status)
					return params.rowNode.allLeafChildren[0]?.data.status;
				} else if (params.rowNode.level == 0) {
					const statuses = params.rowNode.allLeafChildren.map(
						(row) => row.data.status
					);
					// console.log('row is 0 so checking statuses', statuses)
					if (statuses.includes('Failed')) return 'Failed';
					else if (statuses.includes('Pending Review')) return 'Pending Review';
					else return 'Approved';
				}
			},
		},
		masterDetail: false,
		rowSelection: 'multiple',
		suppressContextMenu: false,
		getRowHeight: (params) => {
			if (params.node && params.node.detail) return 430;
		},
		getRowClass: (params) => {
			// console.log('getrowclass params', params.node)
			if (params.node.allChildrenCount) {
				// console.log("allchildren > 0")
				switch (params.node.level) {
					case 0:
						// console.log('is 0')
						return 'group-0-style';
					case 1:
						// console.log('is 1')
						return 'group-1-style';
				}
			}
			return 'testclass base';
		},
		getRowNodeId: (data) =>
			IsKeyInObj(data, 'grid_row_id')
				? data['grid_row_id']
				: IsKeyInObj(data, 'style-color_id')
				? data['style-color_id']
				: uuidv4(),
	};
};

export const TreeDataGridOptions = (
	tabKey: string,
	treeDataParams
) => {
	const {groupLevelExpanded, auto_group_name, hideLowestLevel} = treeDataParams
	return {
		groupMultiAutoColumn: false,
		suppressAggAtRootLevel: true,
		groupSelectsChildren: true,
		suppressRowClickSelection: true,
		groupDefaultExpanded: groupLevelExpanded,
		// Custom Auto Group Column Definition -> menu/filtering is not allowed for groups
		autoGroupColumnDef: {
			headerName: auto_group_name,
			filter: false,
			pinned: 'left',
			sortable: true,
			// suppressMenu: true,
			cellRenderer: 'agGroupCellRenderer',
			cellRendererParams: {
				suppressCount: true,
				checkbox: (params) => {
					// console.log("paramsssss: ", params)
					if (tabKey === 'user_input' && params.node.group) {
						const dataObj = params.node.group ? 'aggData' : 'data';
						const data = { ...params.node[dataObj] };
						const selectedRowData =
							params.context.componentParent.inputLinesSelectedRows;
						const styc_id = data['style-color_id'];
						const store_distro = data['store_distro'];

						return (
							(!selectedRowData?.styc_id && !selectedRowData?.store_distro) ||
							(styc_id === selectedRowData?.styc_id &&
								store_distro === selectedRowData?.store_distro)
						);
					} else {
						return false;
					}
				},
			},
			valueFormatter: (params) => {
				const value = params.value;

				if (value !== null) {
					if (!params.node.group && hideLowestLevel) {
						return '';
					} else if (tabKey === 'buy_summary' && params.node.level === 0) {
						return 'Total';
					} else if (value.includes('~')) {
						return value.split('~')[1];
					} else {
						return value;
					}
				} else {
					return '--';
				}
			},
			valueGetter: (params) => {
				// Update group keys while generating the cell value to create a unique key based on the hierarchy order to prevent duplicate group ids
				if (params.node?.level && params.node.group) {
					let route = [
						params.node.rowIndex,
						...new Set(params.node.getRoute()),
					];
					const strRoute = [...new Set(route.join('|').split('|'))].join('|');

					let updatedKey = params.node.level === 0 ? params.node.key : strRoute;

					if (params.node.level === 0) {
						return updatedKey;
					} else {
						if (route[params.node.level] !== strRoute)
							params.node.key = updatedKey;
						return updatedKey.split('|')[updatedKey.split('|').length - 1];
					}
				} else {
					return params.node.key;
				}
			},
		},
		getDataPath: (data: any) => {
			// console.log("get data path: ", data.file_path)
			let res =
				data.file_path.length > 0
					? data.file_path.map((path) => (path ? path : '...enter a value'))
					: [];

			if (data.file_path_id) {
				// for leaf nodes we add the id to make them unique if the current id does not exist
				if (res && !res.includes(data.file_path_id)) {
					res.push(data.file_path_id);
				}
			}
			return res;
		},
		getRowNodeId: (data: any) => {
			// console.log("get row id: ", data.file_path)
			let res =
				data.file_path.length > 0
					? data.file_path.map((path) => (path ? path : '...enter a value'))
					: [];

			if (data.file_path) {
				if (data.file_path_id) {
					// for leaf nodes we add the id to make them unique if the current id does not exist
					if (res && !res.includes(data.file_path_id)) {
						res.push(data.file_path_id);
					}
				}
				return res.join('|');
			}
		},
	};
};
