// Angular Imports
// =========================================================
import {
  Component, ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output
} from "@angular/core";
import { API, Auth } from 'aws-amplify';
import { cloneDeep } from "lodash";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
// AG Grid Imports
// =========================================================
import {
  CellClickedEvent, ColumnApi, FilterChangedEvent, GridApi, GridOptions, GridReadyEvent
} from "ag-grid-community";
import 'ag-grid-enterprise';
// Prime NG Imports
// =========================================================
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
// Custom Imports
// =========================================================
import { DoesExternalFilterPass, IsExternalFilterPresent } from "src/app/components/02_ag-grid-settings/g-ag-grid-functions";
import { CustomGridOptions } from "src/app/components/02_ag-grid-settings/g-ag-grid-options";
import {
  DefaultDetailGridOptions
} from "src/app/components/02_ag-grid-settings/g-ag-grid-variables";
import { StoreTotalsChartsComponent } from 'src/app/components/03_modals/review-orders/store-totals-charts/store-totals-charts.component';
import { CalcBreaksService } from "src/app/core/services/calc-breaks.service";
import { DataService } from "src/app/core/services/data.service";
import { ReviewOrdersService } from "src/app/core/services/db/review-orders.service";
import { SelectedRowService } from "src/app/core/services/selectedRows.service";
import { handleError } from "src/app/core/utils/error-handling";
import { FormatKey, stripTimestamp } from "../../../../core/utils/global-functions";
import { OM_GenerateGridData } from "../../../02_ag-grid-settings/02_ag-generate-col-Defs/review-orders-grid-config";
import { MismatchChartComponent } from "../../../03_modals/review-orders/mismatch-chart/mismatch-chart.component";
import { PackSummaryConfigComponent } from "../../../03_modals/review-orders/pack-summary-config/pack-summary-config.component";
import { UserInputConfigComponent } from "../../../03_modals/review-orders/user-input-config/user-input-config.component";
import { FilterService } from "src/app/core/services/filter.service";
import { showLoadingModal } from "src/app/core/utils/loading-modal";
import { environment } from "src/environments/environment";
import { FormatNumber } from "src/app/components/02_ag-grid-settings/03_ag-cellFormatters/global-cell-formatters";
import { RO_SharedGridOptions, TreeDataGridOptions } from "./ro-grid-options";
@Component({
  selector: "app-ro-ag-grid",
  templateUrl: "./ro-ag-grid.component.html",
  styleUrls: ["./ro-ag-grid.component.scss"],
  providers: [DialogService],
})
export class OrderManagementGridComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  // Ag Grid Configuration
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: "Order Management Grid Component",
      viewCharts: (params) => this.viewCharts(params),
      openConfigModal: (params) => this.openConfigModal(params),
      openMismatchModal: (params) => this.openMismatchModal(params),
      openPackConfigModal: (params) => this.openPackConfigModal(params),
      // isRowSelectable: (params) => this.isRowSelectable(params)
    },
  };

  loadingMessage = '';
  needDates: any[] = [];
  selectedNeedDate: string;
  renderTreeDataGrid: boolean;
  messages: Subscription;
  stateMachine: string = null
  rowsSelected = false;
  currentDate: Date = new Date();
  disabled: boolean = false;
  position: number;
  ref: DynamicDialogRef;

  filtersApplied: {
    parent_product_id: string[],
    season: string[],
    channel: string[]
  } = {
    parent_product_id: [],
    season: [],
    channel: []
    };

  gridApi: GridApi;
  columnApi: ColumnApi;
  gridOptions: GridOptions = {};
  // Grid Data
  columnDefs: any = [];
  detailColumnDefs: any = []
  rowData: any[] = [];
  rawRowData: any[] = [];
  data: any;
  initialRender: boolean = true;
  addRowData: any[] = [];
  updatingRowData: any[] = [];

  // Global Variables
  selectedStyleData: any;
  dataIndex: number;
  selectRow: any;
  tabName: string = "";
  tabKey: string = "";
  sizesArray: any[] = [];
  actionColumnSelected: boolean = false;

  // Misc
  extraData: any = {};
  exportDisabled = true;
  exportDisabledMessage = "No rows selected for export";
  approvePendingDisabled = true;
  approveDisabledMessage = "No rows selected for approval";
  pendingDisabledMessage = "No rows selected to mark pending";
  rowSelectionLevel: number = 0

  @Input("importTabData") importTabData: any;
  @Input() selectableStycIds: string[] = [];
  @Input() selectedStycId: string;
  @Input() inputLinesSelectedRows: any = {
    store_distro: null,
    styc_id: null,
    channels: [],
    rows: [],
    selectedNeedDate: null
  };

  @Input("filtersApplied")
  set _filtersApplied(filtersApplied: any) {
    // console.log('setting filters applied', filtersApplied)
    // filtersApplied.parent_product_id?.forEach()
    this.filtersApplied = filtersApplied
    this.gridApi?.onFilterChanged();
  }

  @Output() selectedIdForBreaks = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Output() rowsSelectedEvent = new EventEmitter<boolean>();

  constructor(
    public dialogService: DialogService,
    private calcBreaksService: CalcBreaksService,
    private selectedRowService: SelectedRowService,
    private filterService: FilterService,
    private ro_services: ReviewOrdersService,
    private dataService: DataService,
    public elementRef: ElementRef
  ) {
    console.log("r-ag-grid constructor!")
    // Subscribe to side panel filters

    this.filterService.triggerFilterSource$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.filtersApplied = data;
        this.gridApi.onFilterChanged()
      });

    this.calcBreaksService
      .getDataIndex()
      .subscribe((index) => (this.dataIndex = index));
    console.log("TABBBBBBB KEY: ", this.tabKey)
  }

  ngOnInit() {
    const {field, tabKey, isTreeDataGrid} = this.importTabData
    console.log("ng on init - Selected Tab Details: ", this.importTabData)
    this.tabName = field;
    this.tabKey = tabKey
    this.renderTreeDataGrid = isTreeDataGrid

    // Shared Grid Configuations
    const mainOPtions = {
        ...this.customGridOptions,
      //     frameworkComponents: {
      //       ...this.customGridOptions.frameworkComponents
      // },
      ...RO_SharedGridOptions(this.tabKey),
      onFirstDataRendered: (params) => this.onFirstDataRendered(),
      isExternalFilterPresent: () => IsExternalFilterPresent(this.filtersApplied),
      doesExternalFilterPass: (node) => DoesExternalFilterPass(node, this.filtersApplied),
      onSelectionChanged: (event) => this.rowSelectionChanged(event),
      isFullWidthCell: (rowNode) => this.updatingRowData.indexOf(`${rowNode.data?.rec_id}`) >= 0,
      onRowSelected: (event) => this.onSelectedId(event),
      onCellClicked: (params) => this.actionColumnSelected = params.colDef?.refData?.dataType?.includes("action-button") || params.colDef?.refData?.dataType?.includes("open-modal"),
      onCellDoubleClicked: (event: CellClickedEvent) => console.log("cell clicked: ", event),
    }

    // Set custom grid options for the tabs that should be configured with treedata
    if (this.renderTreeDataGrid) {
      this.gridOptions = {
        ...mainOPtions,
        ...TreeDataGridOptions(this.tabKey, this.importTabData?.treeDataParams),
      }
    } else {
      this.gridOptions = mainOPtions
    }
  }
   // On Grid Ready
   onGridReady(params?: GridReadyEvent) {
    console.log("AG Grid is Ready")
    if (params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.gridApi.closeToolPanel();
    }

    // here because we need importTab.id and gridApi for these
    this.getAllRows();
    // Re-select data on the user input tab if data has previously been selected
    if (this.tabKey === "user_input") {
      this.selectedRowService.getSelectedRowId().subscribe((id) => {
        console.log('selectedrowid', id)
        if (id !== null && !this.selectRow) {
          const update = this.gridApi.getRowNode(id);
          if (update && !update.isSelected()) update.setSelected(true);
          this.selectRow = id;
        }
      });
    }
  }

  private getAllRows(): void {
    console.log("calling getallrows", this.inputLinesSelectedRows)
    let body;
    switch (this.tabKey) {
      case 'user_input':
        body = null;
        break;
      case 'store_size_breaks':
      case 'buy_summary':
        body = {
          channels: this.inputLinesSelectedRows.channels,
          row_ids: this.inputLinesSelectedRows.rows.map(r => r[0]),
          styc_id: this.inputLinesSelectedRows.styc_id
        }
        break;
      default:
        body = this.inputLinesSelectedRows;
    }
    this.ro_services.ro_getAllRows(this.importTabData.id, body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ plans }) => { this.rawRowData = cloneDeep(plans) },
        complete: () => {
          if (this.initialRender) {
            console.log('initial render transform row data call')
            this.transformRowData(this.rawRowData);
          }
          else {
            console.log('subsequent render transform row data call')
            this.transformRowData(this.addRowData)
          }
        },
        error: (error) => handleError(this.dialogService, error, 'Error fetching dimensions data')
      })
  }

  transformRowData(objData) {
    console.log("----- Transforming Row Data-------")
    console.log("Initial Render: ", this.initialRender),
      // console.log("Dimensions: ", this.dimensions),
      // console.log("Size order: ", this.sizeOrder),
      // console.log("Seasons: ", this.seasonDates)
    console.log("Data to be formatted: ", objData)
    const sizesSet: any = {}
    const {tabKey, columnDefs} = this.importTabData
    let rowData = [];
    let objDataBadC = [];
    this.selectedNeedDate = this.inputLinesSelectedRows.selectedNeedDate;

    // Transform RowData
    switch (tabKey) {
      case 'user_input':
        rowData = objData.map((data, i) => {
          const rowId = (data.row_id + '_' + data.channel + '_' + data.delivery_date).replace(" ", "_")
          const group =  data.style_color_id + '|' + data.store_distro + '~' + data.status
          // Final object for the grid row
          const row = {
            dim_id: data.dim_id,
            row_id: data.row_id,
            grid_row_id: rowId,
            groupby: data.groupby,
            status: data.status,
            prodId: data.prodId,
            parent_product_id: data.parent_product_id,
            style_color_id: data.style_color_id,
            store_distro: data.store_distro,
            channel: data.channel,
            delivery_date: stripTimestamp(data.delivery_date),
            unit_qty: data.unit_qty,
            gap_pct: data.gap_pct * 100,
            fit_score: data.fit_score,
            delivery_type: data.delivery_type,
            file_path_id: rowId,
            file_path: [ group, data.channel]
          };
          return row;
        });
        break;
      case 'store_size_breaks':
        const objDataRows = objData[0]
        // if (this.initialRender) {
        this.needDates = []
        const channels = {}
        objDataRows.forEach(row => {
          row.need_date = stripTimestamp(row.need_date)
          if (row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            // console.log(`row.styc_id ${row.style_color_id} is not selected styc id ${this.inputLinesSelectedRows.styc_id}`)
            return
          } else if (!this.needDates.includes(row.need_date)) {
            this.needDates.push(row.need_date)
          }
        })
        if (!this.needDates.includes(this.selectedNeedDate)) {
          this.selectedNeedDate = objDataRows[0].need_date
        }
        // badc_sizes = objData.badc_sizes
        // Set the first level of the hirearchy
        // rowData.push({
        //   file_path: ['All Stores'],
        //   location_id: '00000'
        // })
        objDataRows.forEach((main_row, index) => {
          objDataBadC = objData[1][index].badc_sizes
          // timestamp for need_date is already stripped

          // console.log("checking for match to selected need date", this.selectedNeedDate)
          // console.log("row is", main_row.need_date)
          if (main_row.need_date !== this.selectedNeedDate
              || main_row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
              // console.log('styc id mismatch')
              // console.log(main_row.style_color_id)
              // console.log(this.inputLinesSelectedRows.styc_id)
            return
          }

          main_row.stores.forEach((data, i) => {
            const row = {
              groupbyssb: 'Total',
              chart: true,
              calc_breaks_run: main_row.calc_breaks_run,
              row_id: main_row.row_id,
              need_date: main_row.need_date, // timesatmp is already stripped
              dim_id: main_row.dim_id,
              prodId: main_row.style_color_id,
              parent_product_id: main_row.parent_product_id,
              channel: main_row.channel,
              location_id: data.location_id,
              store_index: data.store_index ? data.store_index : 0,
              file_path_id: main_row.channel + '~' + data.location_id,
              file_path: ['Total', main_row.channel]
            };
            channels[main_row.channel] = true
            data.sizes.forEach(size => {
              row[size.product_size.toLowerCase()] = size.contrib_pct ? size.contrib_pct : 0
              sizesSet[size.size_order] = size.product_size
            });
            objDataBadC.forEach(size => {
              // console.log('display order for size', size.size_order)
              // console.log('extradata keys', main_row.channel + '-' + size.product_size)
              // console.log("badc_sizes", objDataBadC)
              const val = objDataBadC.find(ele => ele.product_size === size.product_size)
              if (val?.rad_c) {
                const chanKey = FormatKey(main_row.channel)
                const sizeKey = FormatKey(size.product_size)

                if (!this.extraData.hasOwnProperty(chanKey)) {
                  this.extraData[chanKey] = {}
                }
                this.extraData[chanKey][sizeKey] = val
              }
            })
            rowData.push(row)

          });
        });
        // console.log('ssb row data', rowData)
        this.sizesArray = Object.keys(sizesSet).sort((a, b) => {return parseInt(a) - parseInt(b)}).map(size_order => sizesSet[size_order])
        this.sizesArray.forEach(size => {
          Object.keys(channels).forEach(channel => {
            const chanKey = FormatKey(channel)
            const sizeKey = FormatKey(size)

            if (!this.extraData.hasOwnProperty(chanKey)) {
              this.extraData[chanKey] = {}
            }
            if (!this.extraData[chanKey].hasOwnProperty(sizeKey)) {
              this.extraData[chanKey][sizeKey] = {}
            }
          })
        })
        break;
      case 'store_sku_plan':
        this.needDates = []
        objData.forEach(row => {
          row.need_date = stripTimestamp(row.need_date)
          if (row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          } else if (!this.needDates.includes(row.need_date)) {
            this.needDates.push(row.need_date)
          }
        })
        if (!this.needDates.includes(this.selectedNeedDate)) {
          this.selectedNeedDate = objData[0].need_date
        }
        objData.forEach(main_row => {
          if (main_row.need_date !== this.selectedNeedDate
              || main_row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          }
          const row = {
            groupbyssp: 'Total',
            chart: true,
            row_id: main_row.row_id,
            need_date: main_row.need_date,
            dim_id: main_row.dim_id,
            parent_product_id: main_row.parent_product_id,
            prodId: main_row.parent_product_id,
            channel: main_row.channel,
            location_id: main_row.location_id,
            store_qty: main_row.store_qty,
            file_path_id: main_row.channel + '~' + main_row.location_id,
            file_path: ['All Stores', main_row.channel]
          };
          main_row.sizes.forEach(size => {
            sizesSet[size.size_order] = size.product_size
            row[size.product_size.toLowerCase()] = size.store_size_qty
          });
          rowData.push(row);
        })
        // console.log('ssp row data', rowData)
        this.sizesArray = Object.keys(sizesSet).sort((a, b) => {return parseInt(a) - parseInt(b)}).map(size_order => sizesSet[size_order])
        break;
      case 'buy_summary':
        objData.forEach(main_row => {
          if (main_row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          }
          const row = {
            groupbybuysumm: main_row.metric,
            chart: true,
            row_id: main_row.row_id,
            need_date: stripTimestamp(main_row.need_date),
            dim_id: main_row.dim_id,
            parent_product_id: main_row.parent_product_id,
            prodId: main_row.parent_product_id,
            channel: main_row.channel,
            location_id: main_row.location_id,
            total: main_row.total,
            totalObj: main_row.total_obj,
            metric: main_row.metric,
            file_path_id: main_row.channel + '~' + main_row.location_id,
            file_path: [ `All Stores ${main_row.metric}`, main_row.channel ]
          };
          main_row.sizes.forEach(size => {
            // console.log("finding size for buy", size)
            sizesSet[size.size_order] = size.product_size
            // var val = size.qty_over + size.qty_under
            // if (!val) {
            //   val = size.qty_bought
            // }
            row[size.product_size.toLowerCase()] = size
          });
          // console.log(row["0"])
          // console.log(row["8/10"])
          rowData.push(row);
        })
        // console.log('buy summ row data', rowData)
        this.sizesArray = Object.keys(sizesSet).sort((a, b) => {return parseInt(a) - parseInt(b)}).map(size_order => sizesSet[size_order])
        break;
      case 'source_to_dest_summary':
        // TODO: clean up function
        this.needDates = []
        objData.forEach(row => {
          row.need_date = stripTimestamp(row.need_date)
          if (row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          } else if (!this.needDates.includes(row.need_date)) {
            this.needDates.push(row.need_date)
          }
        })
        if (!this.needDates.includes(this.selectedNeedDate)) {
          this.selectedNeedDate = objData[0].need_date
        }
        objData.forEach(main_row => {
          if (main_row.need_date !== this.selectedNeedDate
              || main_row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          }
          // console.log('main row sizes', JSON.stringify(main_row.sizes))
          const row = {
            row_id: main_row.row_id,
            need_date: main_row.need_date,
            dim_id: main_row.dim_id,
            prodId: main_row.parent_product_id,
            parent_product_id: main_row.parent_product_id,
            channel: main_row.channel,
            location_id: main_row.location_id,
            carton_id: main_row.carton_id,
            carton_qty: main_row.qty,
            config: main_row.sizes,
            file_path_id: main_row.location_id + '~' + main_row.carton_id,
            file_path: [`All Stores`, main_row.channel, main_row.location_id]
          };
          rowData.push(row);
        })
        // console.log('src dest summ row data', rowData)
        break;
      case 'carton_summary':
        this.needDates = []
        objData.forEach(row => {
          row.need_date = stripTimestamp(row.need_date)
          if (row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return
          } else if (!this.needDates.includes(row.need_date)) {
            this.needDates.push(row.need_date)
          }
        })
        if (!this.needDates.includes(this.selectedNeedDate)) {
          this.selectedNeedDate = objData[0].need_date
        }
        objData.forEach(main_row => {
          const cartonsSet: any = {}
          if (main_row.need_date !== this.selectedNeedDate
              || main_row.style_color_id !== this.inputLinesSelectedRows.styc_id) {
            return;
          }
          const row = {
            groupbycartonsumm: main_row.row_id,
            row_id: main_row.row_id,
            dim_id: main_row.dim_id,
            prodId: main_row.parent_product_id,
            parent_product_id: main_row.parent_product_id,
            need_date: main_row.need_date,
            carton_id: main_row.carton_id,
            total_qty: main_row.total,
            config: main_row.sizes
          };
          rowData.push(row);
        })
        // console.log('carton summary row data', rowData)
        this.sizesArray = Object.keys(sizesSet).sort((a, b) => {return parseInt(a) - parseInt(b)}).map(size_order => sizesSet[size_order])
        break;
      default:
        console.error('no tab id!')
    }
    // Set The Row Data
    console.log("formatted Data: ", rowData)
    this.rowData = rowData;
    this.gridApi.setRowData(rowData)

    // Render grids
    console.log("final switch in transformrowdata now")
    switch (tabKey) {
      // Render standard grids
      case 'user_input':
      case 'source_to_dest_summary':
      case 'carton_summary':
        this.renderGrid(columnDefs, [])
        break;
      // Rener Grids with dynamic sizes
      case 'store_size_breaks':
      case 'buy_summary':
      case 'store_sku_plan':
        console.log(`rendering ${this.importTabData} with ${this.sizesArray}`)
        this.renderGrid(columnDefs, this.sizesArray);
        break;
      default:
        console.error('no tab id!')
    }
    this.initialRender = false
  }
  async renderGrid(mainColDefs, detailColDefs) {
    console.log("calling rendergrid")
    const params = {
      tabKey: this.tabKey,
      mainColDefs: mainColDefs,
      detailColDefs: detailColDefs,
    }

    const gridData = await OM_GenerateGridData(params);

    if (gridData) {
      console.log("Review Orders col defs: ", gridData.mainColDefs)
      // Sort the columns
      if (this.tabKey === 'store_size_breaks') {
        this.columnDefs = gridData.mainColDefs.sort((a, b) => b.colId === 'chart' ? -1 : 0)
      } else {
        this.columnDefs = gridData.mainColDefs
      }
    }
  }

  onFirstDataRendered() {
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.columnApi.autoSizeAllColumns()

    if (this.tabKey === "user_input") {
      if (this.inputLinesSelectedRows?.rows?.length && this.inputLinesSelectedRows?.rows?.length > 0) {
        const selectedRows = this.inputLinesSelectedRows?.rows.map(row => row[2])
        console.log("selected rows: ", selectedRows)
        console.log("inputlinesselectedrows.rows: ", this.inputLinesSelectedRows?.rows)
        console.log("inputlinesselectedrows: ", this.inputLinesSelectedRows)

        this.gridApi.forEachNode(node => {
          if (node.data && selectedRows.includes(node.data.grid_row_id)) {
              node.parent.setSelected(true)
              node.parent.setExpanded(true)
              node.parent.parent.setExpanded(true)
          }
        })
      }
    }
  }

   // Nested Detail Grid Configuration
  detailCellRendererParams = (parentParams) => {
    const colDefs = this.detailColumnDefs
    return {
      detailGridOptions: {
        ...DefaultDetailGridOptions,
        suppressRowClickSelection: true,
        context: {
          pageTitle: 'Review Order',
        },
        columnDefs: colDefs,
      },
    }
  };

  resetRowAfterUpdate() {
    console.log("calling resetrowafterupdate")
    this.updatingRowData = []
    this.gridApi.redrawRows()
    this.gridApi.deselectAll()
  }

  // On row change update the current season that is selected
  rowSelectionChanged(event) {
    console.log("called rowSelectionChanged()", event);
    const rows = event.api.getSelectedRows();
    console.log("rows: ", rows)

    if (rows.length === 0) {
      this.rowsSelected = false;
      this.rowsSelectedEvent.emit(false);
      this.inputLinesSelectedRows = {
        store_distro: null,
        styc_id: null,
        channels: [],
        row_ids: [],
        selectedNeedDate: null
      };
    } else {

      this.rowsSelected = true;
      this.rowsSelectedEvent.emit(true);
      console.log("store distro", rows[0].store_distro)
      // TODO: selectedNeedDate ?? multiple delivery dates under one grouping
      this.inputLinesSelectedRows = {
        store_distro: rows[0].store_distro,
        styc_id: rows[0].style_color_id,
        channels: [...(new Set(rows.map(row => row.channel)))],
        rows: rows.map(row => [row.row_id, row.dim_id, row.grid_row_id]),
        selectedNeedDate: rows[0].delivery_date
      }
      console.log("row selection changed new channels", this.inputLinesSelectedRows.channels)
      this.rowsSelectedEvent.emit(this.inputLinesSelectedRows);
    }

    // recheck enabled/disabled state for export, approve, and mark pending buttons
    this.validateButtonsEnabled();

    this.gridApi?.refreshCells( {
          force: true,
          suppressFlash: true,
        });
    this.gridApi.redrawRows();
  }

  validateButtonsEnabled() {
    const rows = this.gridApi.getSelectedRows()
    if (rows.length === 0) {
      this.exportDisabled = true;
      this.exportDisabledMessage = "No rows selected for export";
      this.approvePendingDisabled = true;
      this.approveDisabledMessage = "No rows selected for approval";
      this.pendingDisabledMessage = "No rows selected to mark pending";
    } else {
      this.exportDisabled = false;
      this.exportDisabledMessage = "";
      this.approvePendingDisabled = false;
      this.approveDisabledMessage = "";
      this.pendingDisabledMessage = "";
      for (var i = 0; i < rows.length; i++) {
        const row = rows[i];
        console.log("row status!", row.status)
        if (row.status !== 'Approved') {
          this.exportDisabled = true;
          this.exportDisabledMessage = "Only approved rows may be exported";
          break;
        }
      }
    }
  }

  // Set rowData for the pinned total rows
  getTotalAggRowDisplay() {
    console.log("calling gettotalaggrowdksplay")
    this.gridApi.setPinnedTopRowData([{
      "id": "totals-row",
      "store": "All Store Totals",
      "store_index": ""
    }]);
  }

  selectOptimizationType(cell) {
    console.log("dropdown cell: ", cell);
  }

  openConfigModal(cell) {
    // Get the correct modal to open based on tab name
    const getModalComp = () => {
      switch (this.tabKey) {
        case "user_input":
          return UserInputConfigComponent;
        case "carton_summary":
          return PackSummaryConfigComponent;
        case "buy_summary":
          return MismatchChartComponent;
        default:
          return null;
      }
    };
    const setModalComp = getModalComp();
    console.log("called openConfigModal", cell)
    console.log("setmodalcomp", setModalComp)

    if (setModalComp !== null) {
      this.ref = this.dialogService.open(setModalComp, {
        width: '50%',
        height: '70%',
        showHeader: false,
        // closeOnEscape: false,
        dismissableMask: false,
        data: {
          dimId: cell.node.allLeafChildren[0].data.dim_id,
          rowId: cell.node.allLeafChildren[0].data.row_id
        }
      });
    }
  }

  setDropdownValueForGrids(event) {
    this.transformRowData(this.rawRowData)
  }

  // User Input check box selection
  onSelectedId(event) {
    console.log("selected row: ", event)
    if (this.actionColumnSelected) {
        event.node.setSelected(false)
    } else if (this.tabKey === "user_input") {

      const checkNestedRows = (children) => {
        const isGroup = children.node?.group || children.group;

        if (isGroup) {
          const childData = children.node?.childrenAfterFilter || children.childrenAfterFilter;
          childData.map((child) => {
              child.setExpanded(true)
              checkNestedRows(child);
          });
        }
      };
      // Expand current row and any childen on selection
      if (event.node.group) {
            event.node.setExpanded(true)
            checkNestedRows(event.node);
        }
      }
  }

  onApprove(event) {
    this.ro_services.ro_approveRows(this.gridApi.getSelectedRows().map(row => [row.dim_id, row.row_id]))
    .subscribe({
        next: () => {
          this.gridApi.getSelectedNodes().forEach(node => {
            node.setDataValue("status", 'Approved');
            this.gridApi.refreshCells({force:true});
            this.gridApi.refreshClientSideRowModel('aggregate')
            this.validateButtonsEnabled();
          });
        },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error approving rows')
      })
  }

  onMarkPending() {
    this.ro_services.ro_markRowsPending(this.gridApi.getSelectedRows().map(row => [row.dim_id, row.row_id]))
    .subscribe({
      next: () => {
        this.gridApi.getSelectedNodes().forEach(node => {;
          node.setDataValue("status", 'Pending Review')
          this.gridApi.refreshCells({force:true});
          this.gridApi.refreshClientSideRowModel('aggregate');
          this.validateButtonsEnabled();
        });
      },
      complete: () => { },
      error: (error) => handleError(this.dialogService, error, 'Error marking rows pending')
    })
  }

  async onExport(event) {
    this.loadingMessage = "Exporting rows..."
    const ref = showLoadingModal(
      this.dialogService,
      'Exporting',
      'exporting'
    );
    this.updatingRowData = this.inputLinesSelectedRows.rows
    if (this.inputLinesSelectedRows.rows.length) {
      // CollapsedAllRows(this.gridApi)

      const apiName = 'OrchestratorApiGateway';
      const path = '/task';
      const initBody = {
        body: {
          stateMachineName: `sizeui-orchestrator-service-${environment.stage}-ReviewOrderExportStateMachine`,
          identityId: this.dataService.identityId,
          payload: {
            rows: this.inputLinesSelectedRows.rows.map(row => [row[0], row[1]]) // row[2] is grid_row_id
          }
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      };
      await API.post(apiName, path, initBody).then((response) => {
        this.stateMachine = "ReviewOrderExportStateMachine"
        console.log("export complete: ", response);
        console.log("messages: ", this.messages);
        ref.close();
        // reset these
        this.rowsSelected = false
        this.rowsSelectedEvent.emit(false);
        this.inputLinesSelectedRows = {
          styc_id: null,
          channels: [],
          row_ids: []
        }
        // this will reload table
        // this.onGridReady();
        const selected = this.gridApi.getSelectedNodes()
        console.log("selected nodes", selected)
        console.log(selected.map(node => {
          return {grid_row_id: node.data.grid_row_id}
        }))
        this.gridApi.applyTransaction({ remove: selected.map(node => {
          return {
            grid_row_id: node.data.grid_row_id,
            file_path_id: node.data.file_path_id,
            file_path: node.data.file_path
          }
        })});
        // this.gridApi.deselectAll();
      }).catch(error => {
        ref.close();
        handleError(this.dialogService, error, 'Error exporting rows')
      })
    }
  }

  async onCalculateBreaks() {
    this.loadingMessage = "Calculating breaks..."
    this.updatingRowData = this.inputLinesSelectedRows.rows
    if (this.inputLinesSelectedRows.rows.length) {
      // CollapsedAllRows(this.gridApi)

      const apiName = 'OrchestratorApiGateway';
      const path = '/task';
      const initBody = {
        body: {
          stateMachineName: `sizeui-orchestrator-service-${environment.stage}-CalculateBreaks`,
          identityId: this.dataService.identityId,
          payload: {
            rows: this.inputLinesSelectedRows.rows.map(row => [row[0], row[1]]) // row[2] is grid_row_id
          }
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      };
      await API.post(apiName, path, initBody).then((response) => {
        this.stateMachine = "calculateBreaks"
        console.log("qsor call 2 complete: ", response);
        console.log("messages: ", this.messages);
        this.selectedIdForBreaks.emit(this.inputLinesSelectedRows);
      });
    }
  }

  onViewData() {
    this.selectedIdForBreaks.emit(this.inputLinesSelectedRows);
  }

  // Store/Size Breaks Events

  //
  // TODO: rename to viewTotalsCharts
  viewCharts(cell) {
    console.log("viewCharts", cell)
    const rows: any = this.gridApi.getSelectedRows()
    console.log("rows from api", rows)
    const selectedRows = [cell.data, ...rows]
    console.log("rows frm variable", selectedRows)

    this.ref = this.dialogService.open(StoreTotalsChartsComponent, {
      showHeader: false,
      styleClass: 'g-large-modal',
      // closeOnEscape: false,
      dismissableMask: false,
      data: {
        selectedRows: selectedRows,
        sizes: this.sizesArray,
        extraData: this.extraData,
        params: cell,
      },
    });
  }

  openMismatchModal(cell) {
    console.log('mismatch modal cell', cell)
    var selectedRows = []
    switch (cell.node.level) {
      case 0:
        // pass total
        selectedRows.push(cell.data)
        break;
      case 1:
        // pass this channel
        selectedRows.push(cell.data)
        break;
      case 2:
        // pass this channel and this store
        selectedRows.push(cell.data)
        selectedRows.push(cell.node.parent.aggData)
        break;
    }
    this.ref = this.dialogService.open(MismatchChartComponent, {
      showHeader: false,
      styleClass: 'g-large-modal',
      // closeOnEscape: false,
      dismissableMask: false,
      data: {
        selectedRows: selectedRows,
        sizes: this.sizesArray,
        level: cell.node.level,
        metric: cell.data.metric
      },
    });
  }

  openPackConfigModal(cell) {
    this.ref = this.dialogService.open(PackSummaryConfigComponent, {
      showHeader: false,
      styleClass: 'g-large-modal',
      // closeOnEscape: false,
      dismissableMask: false,
      data: {
        selectedRows: cell.node.data
      },
    });
  }

  ngOnDestroy() {
    console.log('destroying')
    this.ref ? this.ref.close() : null;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions();
      this.gridApi.expireValueCache();
      this.gridApi = null;
    }
  }
}
