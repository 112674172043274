<mat-card
  [ngClass]="{'tile-container': true, 'disabled': disabled}"
  #tooltip="matTooltip"
  [matTooltip]="matTooltipText"
  matTooltipPosition="below"
  [attr.aria-label]="label"
>
  <div [ngClass]="{'tile-body': true, 'disabled': disabled}">
    <img [ngClass]="{'tile-icon': true, 'disabled': disabled}" alt="tile icon" [src]="src" width="75" height="75" />
    <div [ngClass]="{'button-label': true, 'disabled': disabled}">
      <p>{{ label }}</p>
    </div>
  </div>
</mat-card>
