<!-- Header -->
<app-nav-bar pageTitle="Tune ML: Size Adds"></app-nav-bar>
<!-- Main -->
<main class="flex-center-all">
  <mat-card class="display-scroll main-container">
    <!-- If Loading -->
    <div *ngIf="isLoading" class="flex-center-all" style="height: 100%">
      <app-loader [loadingMessage]="'Loading'"></app-loader>
    </div>
    <!-- Top section -->
    <section *ngIf="!isLoading" class="p-grid p-nogutter p-all-0">
      <div class="p-sm-12 p-lg-8 p-all-0">
        <div class="container">
          <div class="graph-header">
            <h3 class="section-title-bar" align="left">
              Product ID: {{ modalSummary.prod_id }}
            </h3>
          </div>
          <div class="graph-body">
            <mat-card>
              <p-chart
                class="chart"
                type="bar"
                model="chartView.horizontalBarModel"
                [data]="lineChartData"
                [options]="lineChartOptions"
              >
              </p-chart>
            </mat-card>
          </div>
        </div>
      </div>

      <div class="p-sm-12 p-lg-4" style="padding: 0px">
        <div style="padding: 4px; display: flex; flex-direction: column">
          <!-- Legend Container -->
          <div>
            <h3 class="section-title-bar" style="text-align: left">Legend</h3>
            <mat-card style="margin: 10px 0px; border: 1px solid #f8f8f8">
              <!-- Size Recommendation To Add -->
              <div class="legend-container">
                <div
                  class="color-block"
                  style="background-color: #63bd85"
                ></div>
                <p class="m-0">Size Recommendation To Add</p>
              </div>
              <!-- Size Recommendation To Ignore -->
              <div class="legend-container">
                <div
                  class="color-block"
                  style="background-color: #ff6283"
                ></div>
                <p class="m-0">Size Recommendation To Ignore</p>
              </div>
              <!-- Based on History -->
              <div class="legend-container">
                <div
                  class="color-block"
                  style="background-color: #6384bc"
                ></div>
                <p class="m-0">Based on History</p>
              </div>
              <!-- Expected Distribution -->
              <div class="legend-container">
                <div style="border-top: 4px dashed black; width: 40px"></div>
                <p class="m-0">Expected Distribution</p>
              </div>
            </mat-card>
          </div>
          <!-- Modal Summary Container -->
          <div class="table-container">
            <h3 class="section-title-bar" style="text-align: left">
              Model Summary
            </h3>
            <mat-card style="margin: 10px 0px; border: 1px solid #f8f8f8">
              <table align="left" class="w-100">
                <!-- Macro Size Offering -->
                <tr>
                  <th>Macro Size Offering</th>
                  <td>
                    <div
                      #tooltip="matTooltip"
                      [matTooltip]="modalSummary.macro_size_range"
                      matTooltipPosition="left"
                      [attr.aria-label]="modalSummary.macro_size_range"
                    >
                      {{ modalSummary.macro_size_range }}
                    </div>
                  </td>
                </tr>
                <!-- Current Size Offering -->
                <tr>
                  <th>Current Size Offering</th>
                  <td>
                    <div
                      #tooltip="matTooltip"
                      [matTooltip]="modalSummary.existing_size_range"
                      matTooltipPosition="left"
                      [attr.aria-label]="modalSummary.existing_size_range"
                    >
                      {{ modalSummary.existing_size_range }}
                    </div>
                  </td>
                </tr>
                <!-- Current Demand Coverage -->
                <tr>
                  <th>Current Demand Coverage</th>
                  <td>
                    <div
                      #tooltip="matTooltip"
                      [matTooltip]="modalSummary.current_coverage"
                      matTooltipPosition="left"
                      [attr.aria-label]="modalSummary.current_coverage"
                    >
                      {{ modalSummary.current_coverage.toFixed(2) }}
                    </div>
                  </td>
                </tr>
                <!-- ML Size Add Count -->
                <tr>
                  <th>ML Size Add Count</th>
                  <td>
                    <div
                      #tooltip="matTooltip"
                      [matTooltip]="modalSummary.total_size_adds"
                      matTooltipPosition="left"
                      [attr.aria-label]="modalSummary.total_size_adds"
                    >
                      {{ modalSummary.total_size_adds }}
                    </div>
                  </td>
                </tr>
                <!-- ML Demand Coverage -->
                <tr>
                  <th>ML Demand Coverage</th>
                  <td>
                    <div
                      #tooltip="matTooltip"
                      [matTooltip]="modalSummary.fitted_demand_coverage"
                      matTooltipPosition="left"
                      [attr.aria-label]="modalSummary.fitted_demand_coverage"
                    >
                      {{ modalSummary.fitted_demand_coverage.toFixed(2) }}
                    </div>
                  </td>
                </tr>
              </table>
            </mat-card>
          </div>
        </div>
      </div>
    </section>
    <!-- Bottom section -->
    <section *ngIf="!isLoading" class="bottom-container">
      <h3 class="section-title-bar" align="left">Your Size Adds</h3>
      <mat-card>
        <div class="checkboxes-main-container display-scroll">
          <div
            [class]="
              'checkbox-container' +
              (size.ml_recommended === false
                ? ' notReco'
                : size.locked || size.ml_recommended === null
                ? ''
                : ' reco')
            "
            *ngFor="let size of modalSummary.size_detail_recs"
          >
            <div class="label-container">
              <label [id]="size.stdSizeCd" class="checkbox-label">{{
                size.size_name
              }}</label>
            </div>
            <mat-checkbox
              color="primary"
              [disabled]="size.locked"
              [(ngModel)]="size.checked"
              aria-labelledby="size.size_name"
            ></mat-checkbox>
          </div>
        </div>
        <div class="submit" align="right">
          <button mat-raised-button color="primary" (click)="onSubmit($event)">
            Submit
          </button>
        </div>
      </mat-card>
    </section>
  </mat-card>
</main>
