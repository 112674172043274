<app-modal-header
  [modalTitle]="modalTitle"
  [numOfSizes]="numOfSizes"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content ">
  <span>
    <h2 style="color:rgb(255, 5, 5); text-align:center;">Warning!!</h2>
    <span class="modal-delete-size-ranges-button">
      <h2>{{numOfSizes}}</h2>
  </span>
  </span>
</div>
<div class="export-size-container">
  <caption style="font-size: 12px; color: red; padding-right: 10px;">* Please export before deleting. </caption>
<button
mat-raised-button
id="download-size-offering"
class="submit-btn"
(click)="onExportFile()"
>
Export
</button>
</div>
<div style="width: 100%; height: 100%; min-height: 200px; overflow-y: auto;">
  <ag-grid-angular
      #mainGrid
      style="height: 100%; width: 100%; padding: 5px;"
      class="ag-theme-alpine ag-grid-container"
      [gridOptions]="gridOptions"
      [debug]="true"
  ></ag-grid-angular>
</div>

<app-modal-footer
  confirmBtnText="Delete"
  [disabled]="disableSubmit"
  closeBtnText="Cancel"
  (click)="deleteSizeRanges($event)"
  (closeModal)="close()"
></app-modal-footer>
