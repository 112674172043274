<amplify-authenticator
	id="authenticator-screen"
	[ngClass]="{ background: true}"
	[hideSignUp]="true"
>
	<ng-template amplifySlot="header">
		<div
			id="sign-in-logo"
			style="
				padding: var(--amplify-space-large);
				text-align: center;
				background-color: white;
			"
		>
			<img
				id="login-logo-sm"
				[src]="environment.assets + '/images/sizeo_blue.png'"
			/>
		</div>
	</ng-template>
</amplify-authenticator>
