<!-- Header -->
<app-nav-bar pageTitle="Review Orders"></app-nav-bar>

<!-- Main -->
<main>
  <div class="g-main-body-container">
    <!-- Filter Panel -->
    <app-filter-sidenav>
      <app-filters-panel [filtersToDisplay]="['channel', 'product']"
        [selectedData]="filtersApplied"></app-filters-panel> 
    </app-filter-sidenav>

    <div class="g-main-container-center">
      <mat-tab-group
        mat-align-tabs="center"
        #changeEvent
        class="w-100"
        [selectedIndex]="selectedIndex"
        (selectedIndexChange)="onSelectedIndex($event)"
      >
        <!-- Main Navigation tabs -->
        <mat-tab
          *ngFor="let tab of tabs; let index = index"
          [label]="tab.field"
          [disabled]="!rowsSelected"
        >
        <!-- [disabled]="tab.id !== 'userInput' && selectableStycIds.length === 0" -->

          <!-- Tab Body -->
          <ng-template matTabContent>
            <!-- If loading -->
            <!-- <div class="loading-container grid-align-center">
            <app-loader></app-loader>
            </div> -->
            <mat-card class="g-mat-card-container">
              <app-ro-ag-grid
                [importTabData]="tab"
                (selectedIdForBreaks)="onSelectedId($event)"
                (rowsSelectedEvent)="onRowsSelectedEvent($event)"
                (refresh)="refresh($event)"
                [selectableStycIds]="selectableStycIds"
                [selectedStycId]="selectedStycId"
                [inputLinesSelectedRows]="inputLinesSelectedRows"
                [filtersApplied]="filtersApplied"
              ></app-ro-ag-grid>
              <!-- (selectedIdForBreaks)="onCalculateBreaks($event)"  -->
            </mat-card>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</main>


