<div>
    <div class="tool-panel-title-container">
        <h3>Manage Changes</h3>
    </div>
    <mat-accordion multi>
    <!-- Added Distros  -->
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header class="tool-panel-section-header-container">
            <mat-panel-title style="padding-left: 10px; color: #033a4e;">
              Added Distros
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngSwitch]="addedDistros.length">
            <p  *ngSwitchCase="0">No added distros.</p>
            <div *ngSwitchDefault>
                <div *ngFor="let added of addedDistros; let i = index">
                    <span style="height: 100%; column-gap: 10px; display: flex; align-items: center; justify-content: space-between;">
                        <!-- Index -->
                        <p>{{i + 1}}. </p>
                        <!-- Param -->
                        <span style="flex: 2">
                          <p style="white-space: pre-wrap;">{{added.parameter_id}}</p>
                        </span>
                        <!-- Button -->
                        <button
                            mat-icon-button
                            [ngClass]="{'icon-button': true}"
                            matTooltip="Delete Store Distro"
                            matTooltipClass="openModal-btn-tooltip"
                            (click)="parentGridContext.undoAddedStoreDistro($event, added, i)"
                        >
                        <span class="material-icons delete-icon align-icon-center"> delete </span>
                        </button>
                    </span>
                </div>
            </div>

          </div>
        </mat-expansion-panel>

    <!-- Deleted Distros -->
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header class="tool-panel-section-header-container">
            <mat-panel-title style="padding-left: 10px; color: #033a4e;">
              Deleted Distros
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngSwitch]="deletedDistros.length">
            <p  *ngSwitchCase="0">No deleted distros.</p>
            <div *ngSwitchDefault>
                <div *ngFor="let deleted of deletedDistros; let i = index">
                    <span style="height: 100%; column-gap: 10px; display: flex; align-items: center; justify-content: space-between;">
                        <!-- Index -->
                        <p>{{i + 1}}. </p>
                        <!-- Param -->
                        <span style="flex: 2">
                          <p style="white-space: pre-wrap;">{{deleted.parameter_id}}</p>
                        </span>
                        <!-- Button -->
                        <button
                            mat-icon-button
                            [ngClass]="{'icon-button': true}"
                            matTooltip="Add Store Distro"
                            matTooltipClass="openModal-btn-tooltip"
                            (click)="parentGridContext.undoDeleteStoreDistro($event, deleted, i)"
                        >
                            <span class="material-icons library_add-icon align-icon-center"> library_add </span>
                        </button>
                    </span>
                </div>
            </div>

          </div>

        </mat-expansion-panel>
      </mat-accordion>


    <!-- Added Distros  -->
    <!-- <section class="added-distros-list">
    <div class="tool-panel-section-header-container">
        <span class="ag-icon ag-icon-tree-closed"></span>
        <h3> Adds</h3>
    </div>
    </section> -->
    <!-- Deleted Distros -->
    <!-- <section class="deleted-distros-list">
        <div class="tool-panel-section-header-container">
            <span class="ag-icon ag-icon-tree-closed"></span>
            <h3>Deleted</h3>
        </div>
    </section> -->
</div>