// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const e = {
  production: false,
  serverUrl: "",
  rootUrl: "",
  loginUrl: '/login',
  assets: '../../../assets',
  wsEndpoint: '',
  wsReconnectInterval: 2000,
  refreshInterval: 60000,
  stage: "nadev",
  apiServiceId: 'j8skbogd12',
  apiWebSocketId: 'cwwnba07vh',
  prodLowLvl: 4,
  btl: true
};

e.wsEndpoint = `wss://${e.apiWebSocketId}.execute-api.us-east-1.amazonaws.com/${e.stage}/`

export const environment = e;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
