<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div>
  <app-loader *ngIf="isLoading" [loadingMessage]="'Promoting Changes'"></app-loader>

  <div *ngIf="!isLoading" class="flex-center-all modal-content">
    <form class="w-100"
    #formDirective="ngForm"
    [formGroup]="createTestScenarioForm">
      <!-- Test Scenario Name -->
      <span class="modal-form-container-full-width">
        <h4 class="modal-form-label">Test Scenario Name:</h4>
        <mat-form-field class="modal-form-input">
          <mat-label>Enter a unique name</mat-label>
          <input matInput type="text" required formControlName="name" />
          <mat-error
            *ngIf="
              createTestScenarioForm.get('name').touched &&
              createTestScenarioForm.get('name').errors
            "
            >{{ setFormError("name") }}</mat-error
          >
        </mat-form-field>
      </span>
      <!-- Select Store Distro -->
      <!-- Add New Size -->
      <span class="modal-form-container-full-width">
        <h4 class="modal-form-label">Store Distro:</h4>
        <mat-form-field class="modal-form-input">
          <mat-label>Select a store disto </mat-label>
          <mat-select required formControlName="store_distro">
            <mat-option [value]="distro" *ngFor="let distro of storeDistrosList">{{
              distro
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              createTestScenarioForm.get('store_distro').touched &&
              createTestScenarioForm.get('store_distro').errors
            "
            >{{ setFormError("store_distro") }}</mat-error
          >
        </mat-form-field>
      </span>
      <!-- Buy Quantity -->
      <span class="modal-form-container-full-width">
        <h4 class="modal-form-label">Buy Quantity:</h4>
        <mat-form-field class="modal-form-input">
          <mat-label>Enter the buy Quantity </mat-label>
          <input matInput required type="number" formControlName="size_of_buy" />
          <mat-error
            *ngIf="
              createTestScenarioForm.get('size_of_buy').touched &&
              createTestScenarioForm.get('size_of_buy').errors
            "
            >{{ setFormError("size_of_buy") }}</mat-error
          >
        </mat-form-field>
      </span>
    </form>
    <div class="modal-form-container-full-width" style="margin: 10px 0px">
      <h4 class="modal-form-label">Submit & Reset:</h4>

      <button
        mat-raised-button
        class="submit-btn modal-form-input"
        type="reset"
        (click)="onSaveAndReset(formDirective)"
        [disabled]="!createTestScenarioForm.valid"
      >
        Save and Continue
      </button>
    </div>
  </div>
</div>

<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Save and Close"
  (update)="onSave(createTestScenarioForm.value)"
  [disabled]="!createTestScenarioForm.valid"
  (closeModal)="close()"
  closeBtnText="Close"
></app-modal-footer>
