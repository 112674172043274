<!-- Header -->
<app-nav-bar pageTitle="Size Intelligence Modules"></app-nav-bar>

<main class="background flex-center-all">
  <mat-card class="main-dashboard-container">
    <!-- Automation Section -->
    <section id="automation-container">
      <h2 class="section-title-bar">Automation</h2>
      <div class="section-list-container">
        <div class="section-list">
          <h3 class="section-list-header">Strategy</h3>
          <app-dashboard-tile
            matTooltip="Review size range performance and ML recommendations to establish next season’s available size offering"
            (click)="onClickNav('/define-size-offerings')"
            label="Define Size Offering"
            [src]="environment.assets + '/images/icons/figure_icon_color.svg'"
          ></app-dashboard-tile>
          <app-dashboard-tile
            matTooltip="Update business parameters that determine carton recommendations and how order quantities are cartonized"
            (click)="onClickNav('/carton-and-buy-parameters')"
            label="Set Carton & Buy Parameters"
            [src]="environment.assets + '/images/icons/box_icon_color.svg'"
          ></app-dashboard-tile>
        </div>
        <div class="section-list">
          <h3 class="section-list-header">Model</h3>
          <!-- Database Icon -->
          <app-dashboard-tile
            matTooltip="Define and manage test scenarios that guide Carton and Buy Parameter decisions"
            (click)="onClickNav('/create-test-scenarios')"
            label="Create Test Scenarios"
            [src]="environment.assets + '/images/icons/database_icon_color.svg'"
          ></app-dashboard-tile>
          <!-- Robot Icon -->
          <app-dashboard-tile
            matTooltip="Review business scenarios and provide the ML model with feedback on size expansion"
            (click)="onClickNav('/tune-ml')"
            label="Tune ML: Size Adds"
            [src]="environment.assets + '/images/icons/robot_icon_color.svg'"
          ></app-dashboard-tile>
        </div>
      </div>
    </section>

    <!-- Execution -->
    <section id="execution-container">
      <h2 class="section-title-bar">Execution</h2>
      <div class="section-list-container">
        <div class="single-section-list">
          <h3 class="section-list-header">Preseason</h3>

          <app-dashboard-tile
            [disabled]="!environment.btl"
            label="Review Orders"
            matTooltip="Review and manage orders"
            [src]="
              environment.assets + '/images/icons/checklist_icon_color.svg'
            "
            (click)="onClickNav('/review-orders')">
          <!-- TODO: Add back for clients that want this feature -->
          <!-- matTooltip="Leverage intelligent automation to efficiently review, edit and finalize future order quantities" -->


        </app-dashboard-tile>
        </div>
      </div>
    </section>
  </mat-card>
</main>
