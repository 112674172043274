
// Custom Imports
// =========================================================
import { ButtonCellRenderer } from './01_ag-custom-components/button-cell-renderer/button-renderer';
import { GridCustomTooltipComponent } from './01_ag-custom-components/grid-custom-tooltip/grid-custom-tooltip.component';
// Grid Functions
// =========================================================
import { AbsSumAggFunc, CustomAverageAggFunc, DisplayFirstChildValAggFunc, HideAggTotalsAggFunc, ParseFloatSumAggFunc, ValueArrayAggFunc }from './g-ag-grid-custom-agg-funcs'
import { DropdownCellRendererComponent } from './01_ag-custom-components/dropdown-cell-renderer/dropdown-cell-renderer.component';
import { FormatNumber, FormatCurrency, FormatPercentage, FormatString, FormatDate } from './03_ag-cellFormatters/global-cell-formatters';
import { CellClassRules, RowClassRules } from './g-ag-grid-cellClassRules';
import { StatusPanelRenderer } from './01_ag-custom-components/status-panel-renderer/status-panel-renderer';
import { RowLoadingCellRenderer } from './01_ag-custom-components/row-loading-renderer/row-loading-renderer';
import { ManageDistroChanges } from './01_ag-custom-components/custom-tool-panels/set-carton-and-buy-params/manage-distro-changes';
import { GridLoadingOverlay } from './01_ag-custom-components/grid-loading-overlay/grid-loading-overlay';
import { SourceDestCartonTooltip } from '../01_pages/03_review-orders/ro-ag-grid/source-dest-carton-tooltip/source-dest-carton-tooltip.component';
import { AutoSelectionCellRenderer } from './01_ag-custom-components/auto-selection-cell-renderer/auto-selection-cell-renderer';
import { IDateFilterParams, ISetFilterParams, RowGroupOpenedEvent } from 'ag-grid-community';
import { AutoInputDelimiterCellEditor } from './01_ag-custom-components/auto-input-delimiter-cell-renderer/auto-input-delimiter-cell-editor';
import { EditableCellIconRenderer } from './01_ag-custom-components/editable-cell-icon-renderer/editable-cell-icon-renderer';
import moment from 'moment';

export const CustomGridOptions = {
    // ----> Default column definitions
    defaultColDef: {
        flex: 1,
        width: 100,
        sortable: true,
        resizable: true,
        valueFormatter: (params) => {
            if (params.value) return params.value
        },
        cellClassRules: CellClassRules,
        // tooltipComponent: 'customTooltip',
    },

    // ----> Auto Group Column Definition
    autoGroupColumnDef: {
        minWidth: 200,
        filterValueGetter: function (params) {
            // console.log("filters: ", params)
            let colGettingGrouped = null;
            colGettingGrouped = params.colDef.showRowGroup;
            const valueForOtherCol = params.api.getValue(
                colGettingGrouped,
                params.node
            );
            return valueForOtherCol;
        },

    },

    // ----> Column Type Settingsc
    columnTypes: {
        editableNumValues: {
			doubleClickEdit: true,
			enableValue: true,
			editable: true,
		},
        numberFormatter: {
            valueFormatter: FormatNumber,
            tooltipValueGetter: (params) => {if(params.colDef.refData.showToolTip)return FormatNumber(params)}
        },
        percentageFormatter: {
            valueFormatter: FormatPercentage,
            tooltipValueGetter: (params) => {if(params.colDef.refData.showToolTip)return FormatPercentage(params)}
        },
        currencyFormatter: {
            valueFormatter: FormatCurrency,
            tooltipValueGetter: (params) => {if(params.colDef.refData.showToolTip)return FormatCurrency(params)}
        },
        customAverage: {
            aggFunc: 'customAverageAggFunc',
            enableValue: true,
            valueGetter: (params) => {
                if (!params.node.group) {
                    const value = Number(params.data[params.colDef.field])
                    return {
                        count: 1,
                        avg: value,
                        value: value
                    }
                }
            },
        },
        stringFormatter: {
			valueFormatter: FormatString,
        },
        dateFormatter: {
            valueFormatter: FormatDate,
            filter: 'agDateColumnFilter',
            filterParams: {
                valueFormatter: FormatDate,
				comparator: (filterLocalDateAtMidnight, cellValue) => {
					console.log("cellvalue: ", cellValue)
					console.log("filterLocalDateAtMidnight: ", filterLocalDateAtMidnight)
					const getFormattedDate = (val) => {
						const d = moment(val).format('L')
						return d === 'Invalid date' ? val : d
					}

					var dateAsString = getFormattedDate(cellValue);
					console.log("dateAsString 2: ", dateAsString)

					if (dateAsString == null) {
					  return 0;
					}

					var dateParts = dateAsString.split('/');
					var year = Number(dateParts[2]);
					var month = Number(dateParts[0]) - 1;
					var day = Number(dateParts[1]);
					var cellDate = new Date(year, month, day);
					console.log("cellDate: ", cellDate)

					if (cellDate < filterLocalDateAtMidnight) {
					  return -1;
					} else if (cellDate > filterLocalDateAtMidnight) {
					  return 1;
					} else {
					  return 0;
					}
				  }
			} as IDateFilterParams,
        },
        checkboxFormatter: {
            // TODO: Add conditional class for cell sizing
            // flex: 0,
            // width: 48,
            headerCheckboxSelection: false,
            checkboxSelection: (params) => {
                // console.log('checkboxselection params', params)
                // FIXME: This is global -> local functions should not be referenced here
                // return params.node.level !== 2
                //     && (params.context.isRowSelectable ? params.context.isRowSelectable(params) : true);
                return true
            },
            cellRenderer: (params) => {
                var value = params.data
                if (params.data) value = params.data[params.colDef.colId]

                if (value === "true" || value === true) return params.node.setSelected(true);
            },
            pinned: "left"
        },
        buttonFormatter: {
            cellRendererFramework: ButtonCellRenderer,
        },
        dropdownFormatter: {
            cellRendererFramework: DropdownCellRendererComponent,
        },
        selectionAutoCompleteFormatter: {
            cellEditor: 'autoComplete',
            cellRendererFramework: EditableCellIconRenderer,
            cellRendererParams: {
                renderer_icon: 'select'
            },
        },
        stringDelimiterFormatter: {
            cellEditor: AutoInputDelimiterCellEditor,
            cellRendererFramework: EditableCellIconRenderer,
            cellRendererParams: {
                renderer_icon: 'edit'
            },
        }
    },
    // ----> Framework Components

    frameworkComponents: {
        buttonCellRenderer: ButtonCellRenderer,
        customTooltip: GridCustomTooltipComponent,
        statusPanelRenderer: StatusPanelRenderer,
        rowLoadingCellRenderer: RowLoadingCellRenderer,
        ManageDistroChanges: ManageDistroChanges,
        gridLoadingOverlay: GridLoadingOverlay,
        sourceDestCartonTooltip: SourceDestCartonTooltip,
        autoComplete: AutoSelectionCellRenderer,
        autoInputDelimiter: AutoInputDelimiterCellEditor
    },
    // ----> Custom Agg Functions
    aggFuncs: {
        'absSum': AbsSumAggFunc,
        'chartAgg': (params) => {
            return true; //ButtonCellRenderer
        },
        'aggValueArray': ValueArrayAggFunc,
        'parseFloatSum': ParseFloatSumAggFunc,
        'displayFirstChildVal': DisplayFirstChildValAggFunc,
        'hideAggTotals': HideAggTotalsAggFunc,
        'customAverage': CustomAverageAggFunc
    },

    // ----> Grid Settings
    rowClassRules: RowClassRules,
    loadingOverlayComponent: 'gridLoadingOverlay',
    loadingOverlayComponentParams: { loadingMessage: 'loading',},
    tooltipShowDelay: 0,
    // rowSelection: "single",
    detailRowAutoHeight: true,
    groupMultiAutoColumn: true,
    groupSuppressBlankHeader: true,
    suppressRowClickSelection: true,
    suppressFieldDotNotation:true,
    suppressAggFuncInHeader: true,
    animateRows: true,
    // suppressContextMenu: true,
    suppressScrollOnNewData: true,

     // ----> Grid Functions
     onCellClicked: (params) => console.log("cell clicked: ", params),
     onFilterChanged: (params) => {
         console.log("filter changed: ", params)
         params.api.refreshCells();
     },
    getContextMenuItems: (params) => DefaultGridContextMenu(params),
    onRowGroupOpened: (event: RowGroupOpenedEvent) => event.columnApi.autoSizeAllColumns(),
    // ----> Footer / Status Bar
    statusBar: {
        statusPanels: [
            // { statusPanel: 'statusPanelRenderer' }
            {
                statusPanel: 'agTotalRowCountComponent', align: 'left'
            },
        ],
},
}

// Default context menu
export const DefaultGridContextMenu = (params) => {
    console.log("context params: ", params)

    const checkNestedRows = (children, action) => {

        if (children.group && children?.childrenAfterFilter?.length > 0) {

            if (action === 'expand') children.setExpanded(true);
            if (action === 'collapse') children.setExpanded(false);

            children.childrenAfterFilter.map((child) => {
                    checkNestedRows(child, action);
            });
        }
    }

    const expandSelectedGroup = params.node.group && !params.node.expanded ? [{
        name: 'Expand Selected Row Group',
        action: () => checkNestedRows(params.node, 'expand')
    }] : []

    const collapseSelectedGroup = params.node.group && params.node.expanded ? [{
        name: 'Collapse Selected Row Group',
        action: () =>  checkNestedRows(params.node, 'collapse')
    }] : []

    const menuItems = [
            ...expandSelectedGroup,
            ...collapseSelectedGroup,
		{
			name: 'Expand All Row Groups',
            action: () => params.api.forEachNode(node => node.setExpanded(true))
            // params.api.expandAll(),
			// cssClasses: ['bold'],
		},
		{
			name: 'Collapse All Row Groups',
            action: () => params.api.forEachNode(node => node.setExpanded(false))
            // params.api.collapseAll(),
		},
		{
			name: 'Fit All Columns',
			action: () => params.api.sizeColumnsToFit(),
		},
		{
			name: 'Auto-size All Columns',
			action: () => params.columnApi.autoSizeAllColumns(),
		},
		// 'autoSizeAll',
		'separator',
		// built in copy item
		'copy',
		'copyWithHeaders',
		'paste',
		'separator',
		'excelExport',
		'csvExport',
	]
	return menuItems
}